import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components/macro';
import { apiUrl, appURL, registryURL } from '../../shared/globals';
import { IdentifierValue } from '../../types/external';
import ActivityIndicator from '../ActivityIndicator';
import { setupAJAXResourceList, getResourceViaResourceCacheV3, getAJAXCacheV3Resource, createElement, getEnglish } from '../AJAXResourceList';

export function renderCompetencyFromRDF(competency: any, itemContainer: Element, relatedItemsMap: Array<any>, renderChildCompetencies: boolean) {
	var container = itemContainer as any;
	container.classList.add("competency2");
	container.innerHTML = "";

	//Competency Content
	var CompetencyContent = container.appendChild(createElement("div", "competencyContent"));
	{
		var CompetencyPrefix = CompetencyContent.appendChild(createElement("div", "competencyPrefix"));
		createTitledBox(CompetencyPrefix, "Competency Category", competency["ceasn:competencyCategory"], "competencyPrefixItem competencyCategory");
		createTitledBox(CompetencyPrefix, "List ID", competency["ceasn:listID"], "competencyPrefixItem listID");
		createTitledBox(CompetencyPrefix, "List ID", competency.ListID, "competencyPrefixItem listID"); //For bnode competencies from the API
		createTitledBox(CompetencyPrefix, "Coded Notation", competency["ceasn:codedNotation"], "competencyPrefixItem codedNotation");
		createTitledBox(CompetencyPrefix, "Coded Notation", competency.CodedNotation, "competencyPrefixItem codedNotation"); //For bnode competencies from the API
		createTitledBox(CompetencyPrefix, "Alt Coded Notation", competency["ceasn:altCodedNotation"], "competencyPrefixItem altCodedNotation");
	}
	{
		var CompetencyBody = CompetencyContent.appendChild(createElement("div", "competencyBody"));
		createTitledBox(CompetencyBody, "Competency Label", competency["ceasn:competencyLabel"], "competencyLabel");
		createTitledBox(CompetencyBody, "Competency Label", competency.CompetencyLabel, "competencyLabel"); //For bnode competencies from the API
		createTitledBox(CompetencyBody, "Competency Text", competency["ceasn:competencyText"], "competencyText");
		createTitledBox(CompetencyBody, "Competency Text", competency.CompetencyText, "competencyText"); //For bnode competencies from the API
		createTitledBox(CompetencyBody, "Comment", competency["ceasn:comment"], "competencyComment", (data => { return getEnglish(data)?.map((item: string) => "<div>" + item + "</div>").join(""); }));
		createTitledBox(CompetencyBody, "Comment", competency.Comment, "competencyComment", (data => "<div>" + data + "</div>")); //For bnode competencies from the API
		createTitledBox(CompetencyBody, "CTID", competency["ceterms:ctid"], "competencyCTID", (data => { return "<b>CTID:</b><a href=\"" + appURL + "/competency/" + data + "\">" + data + "</a>"; }));
	}

	//Competency Extras
	var CompetencyExtras = container.appendChild(createElement("div", "competencyExtras"));
	{
		//Misc
		createItemList(CompetencyExtras, "Identifier URL{s}", competency["ceasn:identifier"], "identifier", renderLinkListItem);
		createItemList(CompetencyExtras, "Identifier URL{s}", competency.TargetNode, "identifier", (item: any, valueBox: any) => {
			if (item && !item.toLowerCase().includes(registryURL.toLowerCase())) {
				renderLinkListItem(item, valueBox);
			}
		}); //For bnode competencies from the API
		//Versions
		createVersionBox(CompetencyExtras, "Latest Version", getEnglish(competency["ceterms:latestVersion"]), "latestVersion");
		createVersionBox(CompetencyExtras, "Next Version", getEnglish(competency["ceterms:nextVersion"]), "nextVersion");
		createVersionBox(CompetencyExtras, "Previous Version", getEnglish(competency["ceterms:previousVersion"]), "previousVersion");
		createVersionIdentifierBox(CompetencyExtras, "Version Identifier", createIdentifier(competency["ceterms:versionIdentifier"]), "versionIdentifier");

		createItemList(CompetencyExtras, "Keyword{s}", getEnglish(competency["ceasn:conceptKeyword"]), "keyword", renderSearchLinkListItem);
		createItemList(CompetencyExtras, "Local Subject{s}", getEnglish(competency["ceasn:localSubject"]), "localSubject", renderSearchLinkListItem);
		createAJAXItemList(CompetencyExtras, "Concept{s}", competency["ceasn:conceptTerm"], "conceptTerm", renderConceptListItem);
		createAJAXItemList(CompetencyExtras, "Complexity Level{s}", competency["ceasn:complexityLevel"], "complexityLevel", renderConceptListItem);
		createAJAXItemList(CompetencyExtras, "Education Level Type{s}", competency["ceasn:educationLevelType"], "complexityLevel", renderConceptListItem);
		createAJAXItemList(CompetencyExtras, "Environmental Hazard Type{s}", competency["ceterms:environmentalHazardType"], "environmentalHazardType", renderConceptListItem);
		createAJAXItemList(CompetencyExtras, "Performance Level Type{s}", competency["ceterms:performanceLevelType"], "performanceLevelType", renderConceptListItem);
		createAJAXItemList(CompetencyExtras, "Physical Capability Type{s}", competency["ceterms:physicalCapabilityType"], "physicalCapabilityType", renderConceptListItem);
		createAJAXItemList(CompetencyExtras, "Sensory Capability Type{s}", competency["ceterms:sensoryCapabilityType"], "sensoryCapabilityType", renderConceptListItem);
		createAJAXItemList(CompetencyExtras, "Has Progression Level{s}", competency["asn:hasProgressionLevel"], "hasProgressionLevel", renderProgressionLevelListItem);

		//Substantiating
		createItemList(CompetencyExtras, "Source Documentation{s}", competency["ceasn:sourceDocumentation"], "sourceDocumentation", renderLinkListItem);
		createAJAXItemList(CompetencyExtras, "Inferred Competenc{ies}", competency["ceasn:inferredCompetency"], "inferredCompetency", renderCompetencyLinkListItem);
		createAJAXItemList(CompetencyExtras, "Substantiating Competency Framework{s}", competency["ceasn:substantiatingCompetencyFramework"], "substantiatingCompetencyFramework", renderResourceLinkListItem);
		createAJAXItemList(CompetencyExtras, "Substantiating Credential{s}", competency["ceasn:substantiatingCredential"], "substantiatingCredential", renderResourceLinkListItem);
		createItemList(CompetencyExtras, "Substantiating Occupation{s}", competency["ceasn:substantiatingOccupation"], "substantiatingOccupation", renderLinkListItem);
		createItemList(CompetencyExtras, "Substantiating Job{s}", competency["ceasn:substantiatingJob"], "substantiatingJob", renderLinkListItem);
		createItemList(CompetencyExtras, "Substantiating Work Role{s}", competency["ceasn:substantiatingWorkRole"], "substantiatingWorkRole", renderLinkListItem);
		createItemList(CompetencyExtras, "Substantiating Task{s}", competency["ceasn:substantiatingTask"], "substantiatingTask", renderLinkListItem);
		createItemList(CompetencyExtras, "Substantiating Resource{s}", competency["ceasn:substantiatingResource"], "substantiatingResource", renderLinkListItem);

		//Alignments
		/*
		competency["test:testAlignment"] = [
			"https://credentialengineregistry.org/resources/ce-58aec6a6-b1e1-4c49-af01-238b9b8f2638",
			"https://credentialengineregistry.org/resources/ce-59c6a9f8-4839-494c-ba71-14e0b9b44c4b",
			"https://credentialengineregistry.org/resources/ce-a5a7d0e8-7bc1-43eb-a13c-91c81c2d8cab",
			"https://credentialengineregistry.org/resources/ce-db440f2e-9a9f-45a0-b624-fc277ec6761f",
			"https://credentialengineregistry.org/resources/ce-20400c85-3eb3-451f-859a-bee31569c52a"
		];
		*/
		//createCompetencyDSPList(CompetencyExtras, "Test Alignment{s}", competency, "test:testAlignment", "testAlignment");
		createCompetencyDSPList(CompetencyExtras, "Exact Alignment{s}", competency, "ceasn:exactAlignment", "exactAlignment");
		createCompetencyDSPList(CompetencyExtras, "Prerequisite Alignment{s}", competency, "ceasn:prerequisiteAlignment", "prerequisiteAlignment");
		createCompetencyDSPList(CompetencyExtras, "Broad Alignment{s}", competency, "ceasn:broadAlignment", "broadAlignment");
		createCompetencyDSPList(CompetencyExtras, "Narrow Alignment{s}", competency, "ceasn:narrowAlignment", "narrowAlignment");
		createCompetencyDSPList(CompetencyExtras, "Major Alignment{s}", competency, "ceasn:majorAlignment", "majorAlignment");
		createCompetencyDSPList(CompetencyExtras, "Minor Alignment{s}", competency, "ceasn:minorAlignment", "minorAlignment");
		createCompetencyDSPList(CompetencyExtras, "Knowledge{s} Embodied", competency, "ceasn:knowledgeEmbodied", "knowledgeEmbodied");
		createCompetencyDSPList(CompetencyExtras, "Skill{s} Embodied", competency, "ceasn:skillEmbodied", "skillEmbodied");
		createCompetencyDSPList(CompetencyExtras, "Abilit{ies} Embodied", competency, "ceasn:abilityEmbodied", "abilityEmbodied");
		createCompetencyDSPList(CompetencyExtras, "Alignment{s} To", competency, "ceasn:alignTo", "alignTo");
		createCompetencyDSPList(CompetencyExtras, "Alignment{s} From", competency, "ceasn:alignFrom", "alignFrom");
		createCompetencyDSPList(CompetencyExtras, "Cross Subject Reference{s}", competency, "ceasn:crossSubjectReference", "crossSubjectReference");
		createCompetencyDSPList(CompetencyExtras, "Is Version Of Reference{s}", competency, "ceasn:isVersionOf", "isVersionOf");

		//Industry/Occupation/Instruction
		/*
		competency["test:testReference"] = [
			{
				"ceterms:targetNodeName": { "en": "Test Reference 1" },
				"ceterms:targetNodeDescription": { "en": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ut sodales lorem." },
				"ceterms:codedNotation": "ABC-123"
			},
			{
				"ceterms:targetNodeName": { "en": "Test Reference 2" },
				"ceterms:targetNodeDescription": { "en": "Sed augue justo, dignissim et laoreet vitae, imperdiet eu enim. Ut ac magna a erat elementum rutrum sed non justo. Nulla porttitor mi vitae odio sagittis feugiat. In volutpat vel lacus quis malesuada." },
				"ceterms:codedNotation": "999-999"
			},
			{
				"ceterms:targetNodeName": { "en": "Test Reference 3" },
				"ceterms:targetNodeDescription": { "en": "Nullam dictum, elit ac pharetra vehicula, nibh libero tempus quam, sit amet hendrerit arcu ligula nec nisi." },
				"ceterms:codedNotation": "XYZ-10101"
			}
		];
		*/
		createItemList(CompetencyExtras, "Test Reference{s}", competency["test:testReference"], "testReference", renderCredentialAlignmentObjectSearchLinkListItem, true);
		createItemList(CompetencyExtras, "Industry Type{s}", competency["ceterms:industryType"], "industryType", renderCredentialAlignmentObjectSearchLinkListItem, true);
		createItemList(CompetencyExtras, "Occupation Type{s}", competency["ceterms:occupationType"], "occupationType", renderCredentialAlignmentObjectSearchLinkListItem, true);
		createItemList(CompetencyExtras, "Instructional Program Type{s}", competency["ceterms:instructionalProgramType"], "instructionalProgramType", renderCredentialAlignmentObjectSearchLinkListItem, true);

	}

	//Competency Children
	if (renderChildCompetencies) {
		var CompetencyChildren = container.appendChild(createElement("div", "competencyChildren"));
		setupAJAXResourceList(CompetencyChildren, competency["ceasn:hasChild"], undefined, (data, itemContainer, itemList) => { renderCompetencyFromRDF(data, itemContainer, relatedItemsMap, true); });
	}

	//Helpers
	function createTitledBox(container: any, label: string, content: any, cssClass: string, handler?: (data: any) => string) {
		if (content) {
			var box = container.appendChild(createElement("div", cssClass));
			box.title = label;
			box.innerHTML = (handler || getEnglish)(content);
		}
	}

	function createVersionBox(container: any, label: string, content: any, cssClass: string, handler?: (data: any) => string) {
		if (content) {
			var box = container.appendChild(createElement("div", cssClass));
			var labelElement = createElement("span", "label");
			var contentElement = createElement("a", "content") as HTMLAnchorElement; // Explicitly cast to HTMLAnchorElement

			labelElement.textContent = label + ": ";
			var processedContent = (handler || getEnglish)(content);
			contentElement.innerHTML = processedContent;
			contentElement.href = content; // Set the href to the original content

			box.appendChild(labelElement);
			box.appendChild(contentElement);
		}
	}

	function createIdentifier(identifier: Array<any>) {
		return (identifier || []).map(source => {
			return {
				IdentifierType: getEnglish(source["ceterms:identifierType"]),
				IdentifierTypeName: getEnglish(source["ceterms:identifierTypeName"]),
				IdentifierValueCode: getEnglish(source["ceterms:identifierValueCode"]),
			} as IdentifierValue;
		});
	}

	function createVersionIdentifierBox(container: any, label: string, content: IdentifierValue[], cssClass: string, handler?: (data: any) => string) {
		if (content && content.length > 0) {
			const box = container.appendChild(createElement("div", cssClass));
			const labelElement = createElement("span", "label");

			labelElement.textContent = label + ": ";
			box.appendChild(labelElement);

			content.forEach((identifier, index)=> {
				const identifierElement = createElement("div", "identifier");

				if (typeof identifier === "string") {
					identifierElement.textContent = identifier;
				} else {
					const identifierText = `${identifier.IdentifierType ? identifier.IdentifierType + " - " : ""}${identifier.IdentifierTypeName} ${identifier.IdentifierValueCode} `;
					identifierElement.textContent = identifierText;
				}
				if (index < content.length - 1) {
					identifierElement.textContent += ", ";
				}
				labelElement.style.display = "inline";
				identifierElement.style.display = "inline";
				box.appendChild(identifierElement);
			});
		}
	}


	function createItemListInternals(container: any, label: string, dataList: Array<any>, cssClass: string, collapsed: boolean) {
		var box = container.appendChild(createElement("div", "itemList collapseBox " + cssClass + (collapsed ? "" : " expanded")));
		var labelBox = box.appendChild(createElement("button", "itemListLabel collapseButton"));
		labelBox.innerHTML = dataList.length + " " + label.replace(/{ies}/g, dataList.length == 1 ? "y" : "ies").replace(/{s}/g, dataList.length == 1 ? "" : "s");
		var valueBox = box.appendChild(createElement("div", "itemListValue collapseContent"));
		labelBox.addEventListener("click", () => {
			box.classList.contains("expanded") ? box.classList.remove("expanded") : box.classList.add("expanded");
		});
		return valueBox;
	}

	function createItemList(container: any, label: string, content: any, cssClass: string, handler: (item: any, valueBox: any) => void, collapsed?: boolean) {
		if (content) {
			var dataList = (Array.isArray(content) ? content : [content]);
			var valueBox = createItemListInternals(container, label, dataList, cssClass, collapsed || false);
			dataList.forEach(item => {
				handler?.(item, valueBox);
			});
		}
	}

	function createAJAXItemList(container: any, label: string, content: any, cssClass: string, successHandler: (data: any, itemContainer: Element, itemList?: Element) => void, collapsed?: boolean) {
		if (content) {
			var dataList = (Array.isArray(content) ? content : [content]);
			var valueBox = createItemListInternals(container, label, dataList, "ajaxList " + cssClass, collapsed || false);
			setupAJAXResourceList(valueBox, dataList, undefined, successHandler, (error: any, itemContainer: any) => {
				itemContainer.classList.remove("resourceListItem");
			});
		}
	}

	function createCompetencyDSPList(container: any, label: string, source: any, property: string, cssClass: string) {
		createAJAXItemList(container, label, source[property], cssClass, renderCompetencyLinkListItem);
		getResourceViaResourceCacheV3(apiUrl + "/detail/descriptionsetbyctid/" + source["ceterms:ctid"] + "?perBranchLimit=-1", (data: any) => {
			console.log("DSP for competency", data);
			if (data.Result.RelatedItemsMap) {
				console.log("Related Items Map for competency", data.Result.RelatedItemsMap);
				var allMatches = data.Result.RelatedItemsMap.filter((mapItem: any) => {
					return mapItem?.Path?.match(new RegExp("< " + property + " < ceasn:Competency$")) != null
				}).flatMap((mapItem: any) => mapItem.URIs) as Array<string>;

				var filteredMatches = [] as Array<string>;
				allMatches.forEach(match => {
					!filteredMatches.includes(match) && filteredMatches.push(match);
				});

				if (filteredMatches?.length > 0) {
					createAJAXItemList(container, "External/Incoming " + label, filteredMatches, cssClass + " external", renderCompetencyLinkListItem)
				}
			}
		});
	}

	function renderLinkListItem(item: any, valueBox: any) {
		var link = valueBox.appendChild(createElement("a", "linkListItem"));
		link.href = item;
		link.innerHTML = item;
		link.target = "_blank";
	}

	function renderSearchLinkListItem(item: any, valueBox: any) {
		if (!item.indexOf) {
			debugger;
		}
		var link = valueBox.appendChild(createElement("a", "linkListItem searchLinkListItem"));
		link.href = item.indexOf("http") == 0 ? item : "/search?searchtype=competencyframework&keywords=" + encodeURIComponent(item);
		link.innerHTML = item;
		link.target = "_blank";
	}

	function renderDetailPageLinkListItem(item: any, valueBox: any) {
		var link = valueBox.appendChild(createElement("a", "linkListItem detailPageLinkListItem"));
		var url = item.replace(registryURL, appURL);
		link.href = url;
		link.innerHTML = url;
		link.target = "_blank";
	}

	function renderConceptListItem(data: any, valueBox: any, listBox: any) {
		valueBox.innerHTML = "";
		var concept = data["@graph"]?.find((m: any) => m["@type"] == "skos:Concept") || data;
		var schemeURI = Array.isArray(concept["skos:inScheme"])
						? concept["skos:inScheme"][0] : concept["skos:inScheme"] || ""
		var renderURL = concept["@id"].includes("credentialengineregistry.org") ? (appURL + "/conceptscheme/ce-" + schemeURI.split("/ce-")[1] + "?targetCTID=" + concept["ceterms:ctid"]) : concept["@id"];
		var renderLabel = getEnglish(concept["skos:prefLabel"]) || renderURL;
		var renderDefinition = getEnglish(concept["skos:definition"]);

		var wrapper = valueBox.appendChild(createElement("div", "concept"));
		var link = wrapper.appendChild(createElement("a", ""));
		link.href = renderURL;
		link.innerHTML = renderLabel;
		if (renderDefinition) {
			var definition = wrapper.appendChild(createElement("div", "description"));
			definition.innerHTML = renderDefinition;
		}
	}

	function renderProgressionLevelListItem(data: any, valueBox: any, listBox: any) {
		valueBox.innerHTML = "";
		var concept = data["@graph"]?.find((m: any) => m["@type"] == "asn:ProgressionLevel") || data;
		var schemeURI = Array.isArray(concept["ceasn:inProgressionModel"]) 
						? concept["ceasn:inProgressionModel"][0] : concept["ceasn:inProgressionModel"] || "";
		var renderURL = concept["@id"].includes("credentialengineregistry.org") ? (appURL + "/progressionmodel/ce-" + schemeURI.split("/ce-")[1] + "?targetCTID=" + concept["ceterms:ctid"]) : concept["@id"];
		var renderLabel = getEnglish(concept["skos:prefLabel"]) || renderURL;
		var renderDefinition = getEnglish(concept["skos:definition"]);

		var wrapper = valueBox.appendChild(createElement("div", "concept"));
		var link = wrapper.appendChild(createElement("a", ""));
		link.href = renderURL;
		link.innerHTML = renderLabel;
		if (renderDefinition) {
			var definition = wrapper.appendChild(createElement("div", "description"));
			definition.innerHTML = renderDefinition;
		}
	}

	function renderCredentialAlignmentObjectSearchLinkListItem(item: any, valueBox: any) {
		var code = item["ceterms:codedNotation"] || "";
		var name = getEnglish(item["ceterms:targetNodeName"]) || "";
		var description = getEnglish(item["ceterms:targetNodeDescription"]) || "";
		var wrapper = valueBox.appendChild(createElement("div", "credentialAlignmentObject"));
		var link = wrapper.appendChild(createElement("a", ""));
		link.href = "/search?searchtype=competencyframework&keywords=" + encodeURIComponent(code || name);
		link.innerHTML = [code, name].filter(m => m).join(": ");
		link.target = "_blank";
		if (description) {
			var descriptionBox = wrapper.appendChild(createElement("div", "description"));
			descriptionBox.innerHTML = description;
		}
	}

	function renderCompetencyLinkListItem(item: any, valueBox: any) {
		valueBox.innerHTML = "";
		valueBox.classList.remove("resourceListItem");
		valueBox.classList.add("competencyLinkListItem");
		var url = item["@id"];
		var frameworkURL = item["ceasn:isPartOf"];
		var collectionURLs = item["ceterms:isMemberOf"];
		var text = getEnglish(item["ceasn:competencyText"]);

		var containerLink = valueBox.appendChild(createElement("a", "competencyContainer"));
		var containerDiv = valueBox.appendChild(createElement("div", "competencyContainer"));
		var separator = valueBox.appendChild(document.createTextNode(": "));
		var itemLink = valueBox.appendChild(createElement("a", ""));

		if (frameworkURL || (collectionURLs && collectionURLs.length == 1)) {
			valueBox.removeChild(containerDiv);
			getResourceViaResourceCacheV3(frameworkURL || collectionURLs[0], (data) => {
				containerLink.href = (data["@id"] || "").replace(registryURL, appURL);
				containerLink.target = "_blank";
				containerLink.innerHTML = getEnglish(data["ceterms:name"] || data["ceasn:name"]);
			});
		}
		else if (collectionURLs && collectionURLs.length > 1) {
			valueBox.removeChild(containerLink);
			containerDiv.innerHTML = collectionURLs.length + " Collections";
		}
		else {
			valueBox.removeChild(containerLink);
			valueBox.removeChild(separator);
		}

		//itemLink.href = url.replace(registryURL, appURL);
		itemLink.href = url.includes(registryURL) ? ( appURL + "/competency/" + item["ceterms:ctid"] ) : url;
		itemLink.target = "_blank";
		itemLink.innerHTML = text;
	}

	function renderResourceLinkListItem(item: any, valueBox: any) {
		valueBox.innerHTML = "";
		valueBox.classList.remove("resourceListItem");
		valueBox.classList.add("renderResourceLinkListItem");
		var ctid = item["ceterms:ctid"];
		var url = appURL + "/resources/" + ctid;
		var link = valueBox.appendChild(createElement("a", "linkListItem detailPageLinkListItem"));
		link.href = url;
		link.innerHTML = getEnglish(item["ceterms:name"] || item["ceasn:name"] || item["skos:prefLabel"] || item["ceasn:competencyLabel"] || "Unknown Name");
		link.target = "_blank";
	}
}

export function CompetencyStyleBlock() {
	return <style type="text/css">{`
		.competency2 { border-top: 1px solid #CCC; padding: 5px 0; }
		.competency2:first-child { border-top: none; } 
		.competency2 .competencyContent { display: flex; gap: 10px; margin-bottom: 5px; border-left: 5px solid #BBB; }
		.competency2 .competencyContent .competencyPrefix { display: flex; gap: 5px; }
		.competency2 .competencyContent .competencyPrefix .competencyPrefixItem { display: flex; flex-direction: column; justify-content: center; background-color: #F5F5F5; font-size: 12px; padding: 5px; min-width: 50px; max-width: 150px; word-break: break-word; }
		.competency2 .competencyContent .competencyPrefix .competencyPrefixItem.competencyCategory { width: 100px; }
		.competency2 .competencyContent .competencyBody {  }
		.competency2 .competencyContent .competencyBody .competencyLabel { font-weight: bold; }
		.competency2 .competencyContent .competencyBody .competencyText { }
		.competency2 .competencyContent .competencyBody .competencyComment { padding: 0 10px; font-size: 12px; font-style: italic; }
		.competency2 .competencyContent .competencyBody .competencyCTID { display: flex; gap: 5px; font-size: 12px; font-style: italic; }
		.competency2 .competencyExtras { padding-left: 20px; border-top: 1px dashed #CCC; }
		.competency2 .competencyExtras:empty { display: none; } 
		.competency2 .competencyExtras .itemList { padding: 5px 0; }
		.competency2 .competencyExtras .itemList .itemListLabel { font-size: 12px; font-style: italic; border: none; background-color: transparent; border-radius: 3px; }
		.competency2 .competencyExtras .itemList .itemListLabel:is(:focus, :hover) { background-color: #EEE; cursor: pointer; }
		.competency2 .competencyExtras .itemList:not(.expanded) .itemListLabel::after { content: "\u23F5"; margin-left: 10px; }
		.competency2 .competencyExtras .itemList.expanded .itemListLabel::after { content: "\u23F7"; margin-left: 10px; }
		.competency2 .competencyExtras .itemList:not(.expanded) .itemListValue { display: none; }
		.competency2 .competencyExtras .itemList .itemListValue { margin-left: 25px; font-size: 12px; }
		.competency2 .competencyExtras .itemList .itemListValue .linkListItem { margin: 2px 5px; }
		.competency2 .competencyExtras .itemList .itemListValue .competencyLinkListItem { padding: 1px 0; }
		.competency2 .competencyExtras .itemList .itemListValue .credentialAlignmentObject {  }
		.competency2 .competencyExtras .itemList .itemListValue .credentialAlignmentObject .description { padding-left: 10px; }
		.competency2 .competencyExtras .itemList .itemListValue .concept .description { padding-left: 10px; }
		.competency2 .competencyChildren { margin-left: 25px; }
	`}</style>
}

/* Properties
asn:hasProgressionLevel
ceasn:abilityEmbodied
ceasn:alignFrom
ceasn:alignTo
ceasn:altCodedNotation
ceasn:author
ceasn:broadAlignment
ceasn:codedNotation
ceasn:comment
ceasn:competencyCategory
ceasn:competencyLabel
ceasn:competencyText
ceasn:complexityLevel
ceasn:comprisedOf
ceasn:conceptKeyword
ceasn:conceptTerm
ceasn:creator
ceasn:crossSubjectReference
ceasn:dateCreated
ceasn:dateModified
ceasn:derivedFrom
ceasn:educationLevelType
ceasn:exactAlignment
ceasn:hasChild
ceasn:identifier
ceasn:inferredCompetency
ceasn:inLanguage
ceasn:isChildOf
ceasn:isPartOf
ceasn:isTopChildOf
ceasn:isVersionOf
ceasn:knowledgeEmbodied
ceasn:license
ceasn:listID
ceasn:localSubject
ceasn:majorAlignment
ceasn:minorAlignment
ceasn:narrowAlignment
ceasn:prerequisiteAlignment
ceasn:shouldIndex
ceasn:skillEmbodied
ceasn:sourceDocumentation
ceasn:substantiatingCompetencyFramework
ceasn:substantiatingCredential
ceasn:substantiatingJob
ceasn:substantiatingOccupation
ceasn:substantiatingOrganization
ceasn:substantiatingResource
ceasn:substantiatingTask
ceasn:substantiatingWorkRole
ceasn:taskEmbodied
ceasn:weight
ceterms:ctid
ceterms:environmentalHazardType
ceterms:hasTask
ceterms:hasWorkforceDemand
ceterms:industryType
ceterms:instructionalProgramType
ceterms:isMemberOf
ceterms:occupationType
ceterms:performanceLevelType
ceterms:physicalCapabilityType
ceterms:sensoryCapabilityType
navy:hasMaintenanceTask
navy:hasSourceIdentifier
navy:hasTrainingTask
*/