import { MainSearchOption } from '../types/internal';
import { MainQueryType } from '../types/external';
import { configName } from './globals';
import credentialIcon from '../assets/images/icons/icon-credentials-blue-green-01.svg';
import organizationIcon from '../assets/images/icons/icon-organization-blue-green-01.svg';
import assessmentIcon from '../assets/images/icons/icon-assessments-blue-green-01.svg';
import courseIcon from '../assets/images/icons/icon-courses-blue-green-01.svg';
import learningopportunityIcon from '../assets/images/icons/icon-learning-opportunities-blue-green-01.svg';
import learningprogramIcon from '../assets/images/icons/icon-learning-programs-blue-green-01.svg';
import competencyframeworkIcon from '../assets/images/icons/icon-competencies-blue-green-01.svg';
import pathwayIcon from '../assets/images/icons/icon-pathways-blue-green-01.svg';
import pathwaySetIcon from '../assets/images/icons/icon-pathways-blue-green-01.svg';
import transfervalueIcon from '../assets/images/icons/icon-transfer-value-blue-green-01.svg';

import supportServiceIcon from '../assets/images/icons/icon-support-service-blue-green-01.svg';
import scheduledOfferingIcon from '../assets/images/icons/icon-scheduled-offering-blue-green-01.svg';
import industryIcon from '../assets/images/icons/icon-occupations-and-industries-blue-green-01.svg';
import jobIcon from '../assets/images/icons/icon-jobs-blue-green-01.svg';
import occupationIcon from '../assets/images/icons/icon-occupations-and-industries-blue-green-01.svg';
import workRoleIcon from '../assets/images/icons/icon-transfer-value-blue-green-01.svg';
import qaActionsIcon from '../assets/images/icons/icon-quality-assurance-blue-green-01.svg';
import rubricsIcon from '../assets/images/icons/icon-scoring-method-blue-green-01.svg';

export const resultDisplayOptions = [
    { key: 'showAll', value: 'Show All' },
    { key: 'showDescription', value: 'Show Descriptions' },
    { key: 'showButton', value: 'Show Buttons' },
];

export const sortOptions = [
    { key: 'sortOrder:MostRelevant', value: 'Sort by Relevance' },
    { key: 'sortOrder:Newest', value: 'Sort by Most Recent' },
    { key: 'sortOrder:Oldest', value: 'Sort by Least Recent' },
    { key: 'sortOrder:AtoZ', value: 'Sort by A-Z' },
    { key: 'sortOrder:ZtoA', value: 'Sort by Z-A' },
];

export const mainSearchTypeOptions: MainSearchOption[] = [
    { key: 'credential', value: 'Credentials', icon: credentialIcon },
	{ key: 'organization', value: 'Organizations', icon: organizationIcon },
	{ key: 'assessment', value: 'Assessments', icon: assessmentIcon },
	{ key: 'learningopportunity', value: 'Learning Opportunities', icon: learningopportunityIcon },
	{ key: 'competencyframework', value: 'Competency Frameworks', icon: competencyframeworkIcon },
	{ key: 'collection', value: "Collections", icon: competencyframeworkIcon },
	{ key: 'pathway', value: 'Pathways', icon: pathwayIcon },
	{ key: 'outcomedata', value: 'Outcome Data', icon: competencyframeworkIcon },
    { key: 'supportservice', value: 'Support Services', icon: supportServiceIcon },
    { key: 'industry', value: 'Industries', icon: industryIcon },
    { key: 'job', value: 'Jobs', icon: jobIcon },
    { key: 'occupation', value: 'Occupations', icon: occupationIcon },
    { key: 'credentialingaction', value: 'Quality Assurance Actions', icon: qaActionsIcon },
    { key: 'rubric', value: 'Rubrics', icon: rubricsIcon },
    { key: 'transfervalue', value: 'Transfer Values', icon: transfervalueIcon },
    { key: 'transferintermediary', value: 'Transfer Intermediaries', icon: transfervalueIcon },
    { key: 'conceptscheme', value: 'Concept Schemes', icon: competencyframeworkIcon },
	{ key: 'progressionmodel', value: 'Progression Models', icon: competencyframeworkIcon },
	{ key: 'workrole', value: 'Work Roles', icon: competencyframeworkIcon },
    { key: 'task', value: 'Tasks', icon: competencyframeworkIcon },

 /*   { key: 'pathwayset', value: 'Pathway Sets', icon: pathwaySetIcon }*/
];

export const mapLocationSearchOption = [
    { key: 'positionType:In', value: 'In' },
	{ key: 'positionType:Near_25', value: 'Near (25 miles)' },
	{ key: 'positionType:Near_50', value: 'Near (50 miles)' },
	{ key: 'positionType:Near_100', value: 'Near (100 miles)' },
];

export const defaultQuery: MainQueryType = {
    SearchType: 'credential',
    Keywords: '',
    MapFilter: {
        PositionType: 'positionType:In',
    },
    PageSize: 20,
    SkipPages: 0,
    SortOrder: 'sortOrder:MostRelevant',
    MainFilters: [],
};

export const defaultMapSetting = {
    center: { lat: 39.8283, lng: -98.5795 },
    zoom: 4,
};

export const statDataLabel: { [key: string]: string } = {
    Organization: 'Organizations',
    Credential: 'Credentials',
    Assessment: 'Assessments',
    LearningOpportunity: 'Learning Opportunities',
    CompetencyFramework: 'Competency Frameworks',
    Pathway: 'Pathways',
    ScheduledOffering: 'Scheduled Offerings',
    SupportService: 'Support Services',
    OutcomeData: 'Outcome Data',
    ProgressionModel: 'Progression Models',
    Industry: 'Industries',
    Job: 'Jobs',
    Occupation: 'Occupations',
	WorkRole: 'Work Roles',
	Task: "Tasks",
    TransferValue: 'Transfer Values',
    TransferIntermediary: 'Transfer Intermediaries',
    PathwaySet: 'Pathway Sets',
    CredentialingAction: 'Quality Assurance Actions',
    Rubric: 'Rubrics'
};
